import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Swiper,Ellipsis,Image,Grid,NavBar,Tabs,Avatar,Tag,SearchBar,List,Modal,Toast
} from 'antd-mobile-v5';
import dayjs from 'dayjs'
import { MessageOutline, SetOutline,RightOutline } from 'antd-mobile-icons'

import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'

import { environment } from '../../service/baseUrl';

import {
    GetUserInfoByOpenId
  } from '../../service/service';


class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
               
            ],

            userInfo: null,

        };
    }

    componentDidMount() {
        var user = localStorage.getItem("user");
        if (user == null || user == undefined) {
            return;
        }

        this.setState({
            userInfo: JSON.parse(user)
        })
        
    }

    // getUserInfo(){
    //     GetUserInfoByOpenId().then(res => {
    //     })
    // }

    getUserPhone() {
        var user = localStorage.getItem("user");
        if (user == null || user == undefined) {
            Toast.show({
              content: '请先点击获取头像',
              afterClose: () => {
                console.log('after')
              },
            })
            return;
        }

        Modal.show({
            title: '绑定手机号',
            content: 
                <>
                    <Form
                        name='form'
                        onFinish={this.onFinish}
                        layout='horizontal'
                        footer={
                            <Button block type='submit' shape='rounded'
                                style={{ color: "#000", width: "50%", backgroundColor: "#d8ff01", margin: "auto" }}
                                color='primary'>
                                提交
                            </Button>
                        }
                    >
                        <Form.Item
                            name='phone'
                            label='手机号'
                            rules={[{ required: true, message: '手机号不能为空' }]}
                        >
                            <Input onChange={console.log} placeholder='请输入手机号' />
                        </Form.Item>

                    </Form>
                </>,
            closeOnMaskClick: true,
        })

    }

    onFinish = (values) => {
        var user = localStorage.getItem("user");
        if (user == null || user == undefined) {
            Toast.show({
              content: '请先点击获取头像',
              afterClose: () => {
                console.log('after')
              },
            })
            return;
        }
        if (values.phone.length !== 11) {
            Toast.show({
              content: '请输入正确的手机号',
              afterClose: () => {
                console.log('after')
              },
            })
            return;
        }

        user = JSON.parse(user);
        user.phone = values.phone;
        user.displayPhone = values.phone.slice(0, 3) + '****' + values.phone.slice(7);

        this.setState({
            userInfo: user
        })

        localStorage.setItem("user", JSON.stringify(user));
        
        Modal.clear();
    }

    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {



        return (
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                {/* <NavBar back='' backArrow={false} style={{'border-bottom':'solid 1px #e4e4e4'}}>
                    我的
                </NavBar> */}

                <div style={{ position: "absolute", zIndex: "-1" }}>
                    <Image src={"https://api.weibinyi.com/images/niannian/my_bg.png"} width={"100%"} height={"100%"} fit='cover' />
                </div>

                <div style={{
                    backgroundColor: "#fff", padding: "6% 5% 5% 5%",
                    borderTop: "1px solid #ececec", borderBottom: "1px solid #ececec", display: "none"
                }}>
                    <Grid columns={4} gap={8}>
                        <Grid.Item span={1}>
                            <div style={{ margin: "12px 0px 6px 12px" }}>
                                <Avatar src={
                                    (this.state.userInfo == undefined || this.state.userInfo.headImg == undefined) ?
                                        "" : this.state.userInfo.headImg}
                                    style={{ '--size': '48px', '--border-radius': '35px', 'margin': 'auto' }} />
                            </div>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            <div style={{ height: "100%", paddingTop: "5%" }}>
                                {
                                    (this.state.userInfo == null || this.state.userInfo == undefined
                                        || this.state.userInfo.nickName == "获取头像") ?
                                        <div style={{
                                            fontSize: "16px", fontWeight: "600",
                                            color: "#0093ff", textDecoration: "underline"
                                        }} onClick={() => {
                                            window.location.href = environment.wxredirecturi;
                                        }}>获取头像</div>
                                        :
                                        <div style={{ fontSize: "16px", fontWeight: "600" }}>{this.state.userInfo.nickName}</div>
                                }
                                {
                                    (this.state.userInfo == null || this.state.userInfo == undefined
                                        || this.state.userInfo.phone == "绑定手机号") ?
                                        <div style={{ color: "#0093ff", textDecoration: "underline", marginTop: "3px" }}
                                            onClick={() => {
                                                this.getUserPhone();
                                            }}>绑定手机号</div>
                                        :
                                        <div>
                                            {this.state.userInfo.displayPhone}
                                        </div>
                                }
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div color='var(--adm-color-primary)'
                                style={{ fontSize: "25px", marginTop: "12%", textAlign: "center" }}>
                                <SetOutline onClick={()=>{
                                    window.location.href = "/seting";
                                }} />
                            </div>
                        </Grid.Item>
                    </Grid>
                </div>



                <div>
                    <div color='var(--adm-color-primary)'
                        style={{ fontSize: "25px", marginRight: "10px", textAlign: "right" }}>
                        <SetOutline color='#fff' onClick={() => {
                            window.location.href = "/seting";
                        }} />
                    </div>
                </div>

                <div style={{ marginTop: "8%" }}>
                    <Avatar src={
                        (this.state.userInfo == undefined || this.state.userInfo.headImg == undefined) ?
                            "" : this.state.userInfo.headImg
                    }
                        style={{ '--size': '70px', '--border-radius': '35px', 'margin': 'auto' }} />
                </div>

                <div style={{ textAlign: "center", color: "#fff", marginTop: "5px" }}>
                    {
                        (this.state.userInfo == null || this.state.userInfo == undefined
                            || this.state.userInfo.nickName == "获取头像") ?
                            <div style={{
                                fontSize: "16px", fontWeight: "600",
                                textDecoration: "underline"
                            }} onClick={() => {
                                window.location.href = environment.wxredirecturi;
                            }}>获取头像</div>
                            :
                            <div style={{ fontSize: "16px", fontWeight: "600" }}>{this.state.userInfo.nickName}</div>
                    }
                    {
                        (this.state.userInfo == null || this.state.userInfo == undefined
                            || this.state.userInfo.phone == "绑定手机号") ?
                            <div style={{
                                color: "#000", textDecoration: "underline"
                                , marginTop: "3px", fontSize: "15px", fontWeight: "500"
                            }}
                                onClick={() => {
                                    this.getUserPhone();
                                }}>绑定手机号</div>
                            :
                            <div style={{ fontSize: "17px", fontWeight: "500", color: "#000" }}>
                                {this.state.userInfo.displayPhone}
                            </div>
                    }
                </div>



                <div style={{ marginTop: "8%" }}>
                    <List mode='card' header='' style={{ border: "1px solid #ececec", borderRadius: "7px" }}>
                        <List.Item onClick={()=>{
                            localStorage.clear();
                            window.location.href = "/shoppingCart";
                        }}>
                            我的订单
      </List.Item>
                        <List.Item onClick={()=>{
                            localStorage.clear();
                            window.location.href = "/myPosts";
                        }}>
                            我的发帖
      </List.Item>
                        <List.Item onClick={()=>{
                            localStorage.clear();
                            window.location.href = "/userAuth";
                        }}>信息认证</List.Item>
                        
                        {/* <List.Item onClick={()=>{
                            localStorage.clear();
                            window.location.href = "/driftingDown";
                        }}>寄思念</List.Item> */}

                        <List.Item onClick={()=>{
                            localStorage.clear();
                            window.location.href = "/home";
                        }}>退出登录</List.Item>
                    </List>
                </div>


                <BottomMenu  position="absolute" selectKey="my" />

            </div>
        )
    }
}

export default Index 