import React, { useRef, useEffect } from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Selector,TextArea,ImageUploader,DatePicker,NavBar,Toast 
} from 'antd-mobile-v5';
import dayjs from 'dayjs'

import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'

import {
    GetMediaFile,CreateWxConfig,GetWXJSSDK
} from '../../service/service';

import { environment } from '../../service/baseUrl';


const AudioPlayer = ({ audioSrc }) => {
    const audioRef = useRef(null);
  
    const handlePlay = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
    };
  
    const handlePause = () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  
    useEffect(() => {
      // 组件挂载时设置音频源文件
      if (audioRef.current) {
        audioRef.current.src = audioSrc;
      }
  
      // 组件卸载时清理（如果需要的话）
      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.src = ''; // 清空src属性，防止内存泄漏
        }
      };
    }, [audioSrc]); // 仅在audioSrc更改时运行此effect
  
    return (
      <div>
        <audio ref={audioRef} controls>
          <source src={audioSrc} type="audio/mpeg" />
          您的浏览器不支持audio元素。
        </audio>
        <button onClick={handlePlay}>播放</button>
        <button onClick={handlePause}>暂停</button>
      </div>
    );
  };


class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
                {
                    key: 0,
                    url: "https://api.weibinyi.com/images/niannian/home_bg.jpg",
                    thumbnailUrl: "https://api.weibinyi.com/images/niannian/home_bg.jpg",
                }
            ],

            localId: "",

            videoUrl: '',
            showVideo: false,
        };
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
        script.async = true;
        document.body.appendChild(script);

        var jsApiList = [
            "onMenuShareAppMessage",
            'startRecord',
            'stopRecord',
            'playVoice',
            'uploadVoice',
            'onVoiceRecordEnd',
        ];

        setTimeout(() => {

            var url = window.location.href.split('#')[0];
            // var url = environment.domainUrl;
            GetWXJSSDK(encodeURIComponent(url)).then(res => {

                var json = JSON.parse(res.result);
                json.jsApiList = jsApiList;
                json.appId = json.AppId;
                json.debug = true;
                json.nonceStr = json.noncestr;
                
                window.wx.config({
                    debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                    timestamp: parseInt(json.timestamp), // 必填，生成签名的时间戳
                    nonceStr: json.nonceStr, // 必填，生成签名的随机串
                    signature: json.signature,// 必填，签名
                    jsApiList: jsApiList, // 必填，需要使用的 JS 接口列表
                    appId: json.appId, // 必填，公众号的唯一标识
                });

            })
        }, 1000);


    }


    uploadVoice = (id) => {
        GetMediaFile(id).then(res => {
            
            if (res.result.item1) {
                this.setState({
                    videoUrl: environment.apiEndpoint + "/Files/yuyin/" + res.result.item2,
                    showVideo: true,
                })

            } else {
                Toast.show({
                    // icon: 'error',
                    content: "内部错误！",
                })
            }

        })
    }


    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div>
                <NavBar back='返回' onBack={() => {
                    window.location.href = "/home";
                }}>
                    语音列表
                </NavBar>


                <Button color='primary' fill='solid' onClick={() => {
                    var win = window;
                    var that = this;

                    win.wx.startRecord() // 开始录音

                    win.wx.onVoiceRecordEnd({
                        // 录音时间超过一分钟没有停止的时候会执行 complete 回调
                        complete: function (res) {
                            var localId = res.localId;

                            that.setState({
                                localId: localId
                            })

                            Toast.show({
                                icon: 'success',
                                content: "录音时间不能超过一分钟！",
                            })

                            win.wx.uploadVoice({
                                localId: localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                                isShowProgressTips: 1, // 默认为1，显示进度提示
                                success: function (res) {
                                    var serverId = res.serverId; // 返回音频的服务器端ID

                                    that.uploadVoice(serverId);

                                }
                            });

                        }
                    });

                }}>
                    开始录音
          </Button>
                <Button color='primary' fill='solid' onClick={() => {
                    var win = window;
                    var that = this;

                    win.wx.stopRecord({
                        success: function (res) {
                            debugger
                            var localId = res.localId;

                            that.setState({
                                localId: localId
                            })


                            win.wx.uploadVoice({
                                localId: localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                                isShowProgressTips: 1, // 默认为1，显示进度提示
                                success: function (res) {
                                    var serverId = res.serverId; // 返回音频的服务器端ID

                                    that.uploadVoice(serverId);

                                }
                            });

                        }
                    });
                }}>
                    停止录音
          </Button>


                <Button color='primary' fill='solid' onClick={() => {

                    window.wx.playVoice({
                        localId: this.state.localId // 需要播放的音频的本地ID，由stopRecord接口获得
                    });
                }}>
                    播放录音
          </Button>


                {
                    this.state.showVideo ?
                        <div>
                            <AudioPlayer audioSrc={this.state.videoUrl} />
                        </div> : null
                }

                <BottomMenu  position="absolute" selectKey="home" />

            </div>
        )
    }
}

export default Index 