export const environment = {
    
    // apiEndpoint: 'http://localhost:21021',
    
    QNYImgUrl: 'http://cdn.weibinyi.com/',
    QNYImgUrlYS: '?imageView2/4/w/400/h/600/q/100',

    wxredirecturi: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx46a1913d55fa5abf&redirect_uri=https://niannian.weimuyuan.com/&response_type=code&scope=snsapi_userinfo&state=home#wechat_redirect',

    // apiEndpoint: 'http://47.103.153.128:9001',
    apiEndpoint: 'https://api.weibinyi.com',
    

    domainUrl: "https://niannian.weimuyuan.com",
    appid: "wx46a1913d55fa5abf",

};