import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Selector,Ellipsis,List,Avatar,NavBar,Tabs
} from 'antd-mobile-v5';
import dayjs from 'dayjs'

import { RightOutline } from 'antd-mobile-icons'
import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'


class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
               
            ],

        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);

        setTimeout(() => {
            var item = document.getElementsByClassName("am-list-item");
            if (item.length > 0) {
                for (let index = 0; index < item.length; index++) {
                    const element = item[index];
                    // element.style.marginBottom = "5px";

                    element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    element.style.paddingLeft = "0px";
                    if (element.innerText == "备注") {
                        element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    }

                }
            }
        }, 200);
    }

    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                <NavBar back='返回' onBack={() => {
                    window.location.href = "/home";
                }} style={{ 'border-bottom': 'solid 1px #e4e4e4' }}
                    right={<a href='/msgAdd' style={{ fontSize: "13px" }}>去留言<RightOutline /></a>}>
                    留言板列表
                </NavBar>

                <Tabs defaultActiveKey='1' padding='0' style={{
                    borderBottom: "solid 0px", position: "sticky",
                    top: "0px", zIndex: "100", backgroundColor: "#fff"
                }}>
                    <Tabs.Tab title='所有' key='1' />
                    <Tabs.Tab title='亲属' key='2' />
                    <Tabs.Tab title='同事' key='3' />
                    <Tabs.Tab title='朋友' key='4' />
                    <Tabs.Tab title='同学' key='5' />
                    <Tabs.Tab title='其他' key='6' />
                </Tabs>


                <div style={{ marginBottom: "17%" }}>
                    <List>

                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>
                        <List.Item
                            prefix={<Avatar src={"https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"} />}
                            description={
                                <Ellipsis
                                    direction='end'
                                    content={"如果你不传图片，或者图片加载失败，那么会显示一个默认的图像"}
                                    expandText='展开'
                                    collapseText=' 收起'
                                />
                            }
                        >
                            张三
                        </List.Item>


                    </List>

                    

                </div>

                <BottomMenu  position="absolute" selectKey="home" />

            </div>
        )
    }
}

export default Index 