import React from 'react'

import {
    Form,
    Tabs,
  Badge,
  TabBar,
  Button,ImageViewer,SideBar,List,Image,Grid,Popup,Stepper,Modal, Toast
} from 'antd-mobile-v5';
import { HandPayCircleOutline,StarOutline,StarFill } from 'antd-mobile-icons'

import BottomMenu from '../../comronent/BottomMenu/index';
import 'antd/dist/antd.css';
import '../../assets/font/font.css'
import '../Home/index.css'

import {
    GetDeathList,GetWXJSSDK
} from '../../service/service';

import { environment } from '../../service/baseUrl';

class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            dataMsg: "数据加载中...",

            visibleQF: false,
            visibleQFOver: false,
            visibleXH: false,
            visibleImg: false,
        };
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
        script.async = true;
        document.body.appendChild(script);


        // this.onChangeBackground("url(https://api.weibinyi.com/images/niannian/home_bg.jpg)", "100% 100%", "home");
        // this.onChangeBackground("url(https://api.weibinyi.com/images/niannian/home_shu.png)", "100% 100%", "shu");
        this.onChangeBackground("url(https://api.weibinyi.com/images/niannian/home_fu.png)", "35px 35px", "fu");
        this.onChangeBackground("url(https://api.weibinyi.com/images/niannian/home_hua.png)", "35px 35px", "hua");
        this.onChangeBackground("url(https://api.weibinyi.com/images/niannian/home_edit.png)", "35px 35px", "edit");
        this.onChangeBackground("url(https://api.weibinyi.com/images/niannian/home_yuyin.png)", "35px 35px", "yuyin");
        this.onChangeBackground("url(https://api.weibinyi.com/images/niannian/home_img.png)", "35px 35px", "img");
        // this.onChangeBackground("url(https://api.weibinyi.com/images/niannian/home_share.png)", "50px 40px", "share");


        setTimeout(() => {
            var ele = document.getElementsByClassName("landscape");
            var div = document.getElementById("bottom");
            ele[0].style.bottom = div.offsetHeight + "px";
        }, 500);

        var jsApiList = [
            'checkJsApi',
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'onMenuShareWeibo',
            'onMenuShareQZone',
            'updateAppMessageShareData',
            'updateTimelineShareData'
        ];

        setTimeout(() => {

            var url = window.location.href.split('#')[0];
            url = encodeURIComponent(url);

            GetWXJSSDK(url).then(res => {

                var json = JSON.parse(res.result);
                json.jsApiList = jsApiList;
                json.appId = json.AppId;
                json.debug = true;
                json.nonceStr = json.noncestr;
                // window.wx.config(json);
                
                window.wx.config({
                    debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                    appId: json.appId, // 必填，公众号的唯一标识
                    timestamp: parseInt(json.timestamp), // 必填，生成签名的时间戳
                    nonceStr: json.nonceStr, // 必填，生成签名的随机串
                    signature: json.signature,// 必填，签名
                    jsApiList: jsApiList, // 必填，需要使用的 JS 接口列表
                });

                window.wx.ready(function () {

                    //“分享给朋友”及“分享到QQ”
                    window.wx.updateAppMessageShareData({
                        title: '张三三的主页', // 分享标题
                        desc: '张三三的主页', // 分享描述
                        link: window.location.href.split('#')[0], // 分享链接
                        imgUrl: 'https://wx.qlogo.cn/mmopen/o0zAzQ5ribdOxyfwU6ianQmTibQI4cGI8GRSzAuCSKPWQnDzGCBen90Hmlzdsmb0CefsqaBHiaicoLP4epNLnGQcWaXeQ60wZRf8g/64', // 分享图标
                        success: function () {
                            // Toast.show({
                            //     content: "分享成功"
                            // });
                        },
                        cancel: function () {
                            Toast.show({
                                content: "取消分享"
                            });
                        }
                    });
                    //“分享到朋友圈”及“分享到QQ空间”
                    window.wx.updateTimelineShareData({
                        title: '张三三的主页', // 分享标题
                        desc: '张三三的主页', // 分享描述
                        link: window.location.href.split('#')[0], // 分享链接
                        imgUrl: 'https://wx.qlogo.cn/mmopen/o0zAzQ5ribdOxyfwU6ianQmTibQI4cGI8GRSzAuCSKPWQnDzGCBen90Hmlzdsmb0CefsqaBHiaicoLP4epNLnGQcWaXeQ60wZRf8g/64', // 分享图标
                        success: function () {
                            // Toast.show({
                            //     content: "分享成功"
                            // });
                        },
                        cancel: function () {
                            Toast.show({
                                content: "取消分享"
                            });
                        }
                    });
                    //分享到朋友圈
                    window.wx.onMenuShareTimeline({
                        title: '张三三的主页', // 分享标题
                        desc: '张三三的主页', // 分享描述
                        link: window.location.href.split('#')[0], // 分享链接
                        imgUrl: 'https://wx.qlogo.cn/mmopen/o0zAzQ5ribdOxyfwU6ianQmTibQI4cGI8GRSzAuCSKPWQnDzGCBen90Hmlzdsmb0CefsqaBHiaicoLP4epNLnGQcWaXeQ60wZRf8g/64', // 分享图标
                        success: function () {
                            // Toast.show({
                            //     content: "分享成功"
                            // });
                        },
                        cancel: function () {
                            Toast.show({
                                content: "取消分享"
                            });
                        }
                    });
                    //分享给朋友
                    window.wx.onMenuShareAppMessage({
                        title: '张三三的主页', // 分享标题
                        desc: '张三三的主页', // 分享描述
                        link: window.location.href.split('#')[0], // 分享链接
                        imgUrl: 'https://wx.qlogo.cn/mmopen/o0zAzQ5ribdOxyfwU6ianQmTibQI4cGI8GRSzAuCSKPWQnDzGCBen90Hmlzdsmb0CefsqaBHiaicoLP4epNLnGQcWaXeQ60wZRf8g/64', // 分享图标
                        success: function () {
                            Toast.show({
                                content: "分享成功"
                            });
                        },
                        cancel: function () {
                            Toast.show({
                                content: "取消分享"
                            });
                        }
                    });

                    

                })
            })
        }, 1000);

    }

    onChangeBackground(url, size, name) {
        var index = document.getElementById(name);
        index.style.backgroundImage = url;
        index.style.backgroundSize = size;
        index.style.zIndex = "100";

    }


    showImg() {
        Modal.show({
            content: <Image fit='cover'
                src="https://api.weibinyi.com/images/niannian/home_hua.png" />,
            closeOnMaskClick: true,
            actions: [],
        })

    }


    render() {

        const onFinish = () => {
            var value = this.formRef.current.getFieldsValue();

            // this.formRef.current.setFieldsValue({
            //   note: 'Hi there!',
            // });
            console.log('Success:', value);

            this.props.submitClick();
        };

        return (
            <div id="home" className="home" style={{ height: "100%", height: "100%" }}>

                <div id="shu" style={{ width: "55%", height: "35%", position: "absolute", marginTop: "75%", marginLeft: "23%" }}>
                </div>
                
                <div id="fu" style={{
                    width: "35px", right: "5px", height: "35px", position: "absolute", marginTop: "90%"
                    , backgroundRepeat: "no-repeat", backgroundPosition: "right"
                }} onClick={()=>{
                    this.setState({
                        visibleQF: true,
                    })
                }}>
                </div>
                <div id="hua" style={{
                    width: "35px", right: "5px", height: "35px", position: "absolute", marginTop: "103%"
                    , backgroundRepeat: "no-repeat", backgroundPosition: "right"
                }} onClick={()=>{
                    this.setState({
                        visibleXH: true,
                    })
                }}>
                </div>

                
                <div id="edit" style={{
                    width: "35px", height: "35px", position: "absolute", marginTop: "10%"
                    , backgroundRepeat: "no-repeat", backgroundPosition: "left", marginLeft: "2%"
                }} onClick={()=>{
                    window.location.href = "/msg";
                }}>
                </div>
                <div id="yuyin" style={{
                    width: "35px", height: "35px", position: "absolute", marginTop: "22%"
                    , backgroundRepeat: "no-repeat", backgroundPosition: "left", marginLeft: "2%"
                }} onClick={()=>{
                    window.location.href = "/medias";
                }}>
                </div>
                <div id="img" style={{
                    width: "35px", height: "35px", position: "absolute", marginTop: "34%"
                    , backgroundRepeat: "no-repeat", backgroundPosition: "left", marginLeft: "2%"
                }} onClick={()=>{
                    window.location.href = "/albumClass";
                }}>
                </div>


                {/* <div id="share" style={{
                    width: "50px", height: "40px", position: "absolute", marginTop: "8%"
                    , backgroundRepeat: "no-repeat", backgroundPosition: "left", right: "0px", marginRight: "20px"
                }} onClick={()=>{
                    debugger
                    Toast.show({
                        content: 333
                    });
                }}>
                </div> */}

                
                <div style={{
                    textAlign: "center", color: "#fff", marginTop: "8%"
                    , zIndex: "99", position: "absolute", width: "100%"
                }}>
                    <div>
                        <div style={{ margin: "auto", width: "40px" }} onClick={() => {
                            window.location.href = "/formView";
                        }}>
                            {/* <StarFill color='#e4d84f' fontSize={36} /> */}
                            <Image src={"https://api.weibinyi.com/images/niannian/home_star1.png"}
                                width={35} height={35} fit='cover' />
                        </div>
                    </div>
                    <div style={{ marginTop: "2%" }}>
                        <span style={{ fontSize: "20px" }} onClick={() => {
                            window.location.href = "/formView";
                        }}>
                            张三三
                        </span>
                    </div>
                    <div>
                        <span style={{ fontSize: "14px" }} onClick={() => {
                            window.location.href = "/formView";
                        }}>
                            2020.01.01-2020.01.01
                        </span>
                    </div>
                </div>


                <Popup
                    visible={this.state.visibleQF}
                    onMaskClick={() => {
                        this.setState({
                            visibleQF: false,
                        })

                    }}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        minHeight: '40vh',
                    }}
                >
                    <div style={{ margin: "5%" }}>
                        <Grid columns={3} gap={8}>
                            <Grid.Item>
                                <Image style={{ width: "80%", margin: "auto", marginTop: "10%" }}
                                    src={"https://api.weibinyi.com/images/niannian/home_qf1.png"} />
                            </Grid.Item>
                            <Grid.Item>
                                <Image style={{ width: "80%", margin: "auto", marginTop: "10%" }}
                                    src={"https://api.weibinyi.com/images/niannian/home_qf1.png"} />
                            </Grid.Item>
                            <Grid.Item>
                                <Image style={{ width: "80%", margin: "auto", marginTop: "10%" }}
                                    src={"https://api.weibinyi.com/images/niannian/home_qf1.png"} />
                            </Grid.Item>
                            <Grid.Item>
                                <Image style={{ width: "80%", margin: "auto", marginTop: "10%" }}
                                    src={"https://api.weibinyi.com/images/niannian/home_qf1.png"} />
                            </Grid.Item>
                            <Grid.Item>
                                <Image style={{ width: "80%", margin: "auto", marginTop: "10%" }}
                                    src={"https://api.weibinyi.com/images/niannian/home_qf1.png"} />
                            </Grid.Item>
                            <Grid.Item>
                                <Image style={{ width: "80%", margin: "auto", marginTop: "10%" }}
                                    src={"https://api.weibinyi.com/images/niannian/home_qf1.png"} />
                            </Grid.Item>
                        </Grid>

                    </div>

                    <div style={{ margin: "5%" }}>
                        <Button block shape='rounded' color='success' onClick={()=>{
                            this.setState({
                                visibleQF: false,
                                visibleQFOver: true,
                            })
                        }}>
                            确定翻开
                        </Button>
                    </div>

                </Popup>
                

                <Popup
                    visible={this.state.visibleQFOver}
                    onMaskClick={() => {
                        this.setState({
                            visibleQFOver: false,
                        })

                    }}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        minHeight: '40vh',
                    }}
                >
                    <div style={{ textAlign: "center", marginTop: "10px", fontSize: "16px" }}>
                        您抽到了<span style={{ fontWeight: "700", marginLeft: "5px" }}>平安健康</span>
                    </div>
                    <div style={{ position: "absolute", fontWeight: "900", right: "5px", marginTop: "-10px" }}>
                        集满有特效
                    </div>
                    <div style={{ margin: "5%" }}>
                        <Image style={{ margin: "auto", width: "20%", margin: "auto" }}
                            src={"https://api.weibinyi.com/images/niannian/home_qf2.png"} />

                    </div>
                    <div style={{ margin: "5%" }}>
                        <Grid columns={2} gap={8}>
                            <Grid.Item>
                                <Button block shape='rounded' color='success'>
                                    重新抽取
                        </Button>
                            </Grid.Item>
                            <Grid.Item>
                                <Button block shape='rounded' color='success'>
                                    祈福
                        </Button>
                            </Grid.Item>

                        </Grid>
                    </div>

                </Popup>



                <ImageViewer
                    image={"https://api.weibinyi.com/images/niannian/home_hua.png"}
                    visible={this.state.visibleImg}
                    onClose={() => {
                        this.setState({
                            visibleImg: false,
                        })
                    }}
                />

                <Popup
                    visible={this.state.visibleXH}
                    onMaskClick={() => {
                        this.setState({
                            visibleXH: false,
                        })

                    }}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        minHeight: '40vh',
                    }}
                >
                    
                    <div style={{ marginTop: "6px" }}>


                        <Tabs defaultActiveKey='1' padding='0' style={{
                            borderBottom: "solid 0px", position: "sticky",
                            top: "0px", zIndex: "100", backgroundColor: "#fff"
                        }}>
                            <Tabs.Tab title='全部' key='1' />
                            <Tabs.Tab title='鲜花' key='2' />
                            <Tabs.Tab title='上香' key='3' />
                            <Tabs.Tab title='贡品' key='4' />
                            <Tabs.Tab title='其他' key='5' />
                        </Tabs>


                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "stretch" }}>
                            <div style={{ flex: "none", overflow: "auto", display: "none" }}>
                                <SideBar>
                                    <SideBar.Item
                                        key='key1'
                                        title={
                                            <div>全部</div>
                                        }
                                    />
                                    <SideBar.Item
                                        key='key2'
                                        title={
                                            <div>鲜花</div>
                                        }
                                    />
                                    <SideBar.Item
                                        key='key3'
                                        title={
                                            <div>上香</div>
                                        }
                                    />
                                    <SideBar.Item
                                        key='key4'
                                        title={
                                            <div>贡品</div>
                                        }
                                    />
                                </SideBar>
                            </div>


                            <div style={{
                                marginLeft: "5%", marginRight: "5%"
                                , flex: "auto", overflow: "auto", height: "400px"
                            }}>

                                <List>
                                    <List.Item
                                        key={"1"}
                                        prefix={
                                            <Image
                                                src={"https://api.weibinyi.com/images/niannian/home_hua.png"}
                                                style={{ borderRadius: 20 }}
                                                fit='cover'
                                                width={40}
                                                height={40}
                                                onClick={() => {
                                                    this.showImg();
                                                }}
                                            />
                                        }
                                        extra={
                                            <Stepper digits={0} min={0} defaultValue={0}
                                                style={{
                                                    '--border-radius': '10px',
                                                    '--input-background-color': '#fff0',
                                                    '--input-width': '24px',
                                                    '--input-font-size': '16px'
                                                }}
                                                onChange={value => {
                                                    console.log(value)
                                                }} />
                                        }
                                        description={
                                            <>
                                                使用时长为24小时
                                            </>
                                        }
                                    >
                                        鲜花
                                    </List.Item>
                                    <List.Item
                                        key={"2"}
                                        prefix={
                                            <Image
                                                src={"https://api.weibinyi.com/images/niannian/home_hua.png"}
                                                style={{ borderRadius: 20 }}
                                                fit='cover'
                                                width={40}
                                                height={40}
                                                onClick={() => {
                                                    this.showImg();

                                                    // this.setState({
                                                    //     visibleImg: true,
                                                    // })
                                                }}
                                            />
                                        }
                                        extra={
                                            <Stepper digits={0} min={0} defaultValue={0}
                                                style={{
                                                    '--border-radius': '10px',
                                                    '--input-background-color': '#fff0',
                                                    '--input-width': '24px',
                                                    '--input-font-size': '16px'
                                                }}
                                                onChange={value => {
                                                    console.log(value)
                                                }} />
                                        }
                                        description={
                                            <>
                                                使用时长为24小时
                                            </>
                                        }
                                    >
                                        鲜花
                                    </List.Item>
                                </List>
                               

                            </div>

                        </div>
                        <div style={{borderTop:"solid 1px #dfdfdf"}}>
                            <Grid columns={5} gap={8}>
                                <Grid.Item span={3}>
                                    <div style={{ lineHeight: "50px", marginLeft: "15px", fontSize: "14px" }}>
                                        <HandPayCircleOutline /> 
                                        <span style={{ marginLeft: "5px" }}>
                                            总金额：
                                            <span style={{ color: "red" }}>10</span>元
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={2}>
                                    <Button block shape='rounded' onClick={() => {

                                        Modal.show({
                                            content: <>
                                                <div style={{
                                                    paddingBottom: "10px", paddingLeft: "10px"
                                                    , borderBottom: "solid 1px #e6e6e6"
                                                }}>
                                                    产品列表
                                                </div>
                                                <div style={{ marginLeft: "10%", marginTop: "15px" }}>
                                                    <Grid columns={6} gap={8}>
                                                        <Grid.Item span={2} style={{ textAlign: "end", fontWeight: "900", textAlign: "left" }}>
                                                            鲜花1
                                </Grid.Item>
                                                        <Grid.Item span={4}>
                                                            *1
                                </Grid.Item>
                                                        <Grid.Item span={2}
                                                            style={{
                                                                textAlign: "end", fontWeight: "900", textAlign: "left"
                                                                , display: this.state.display
                                                            }}>
                                                            鲜花2
                                </Grid.Item>
                                                        <Grid.Item span={4} style={{ display: this.state.display }}>
                                                            *11
                                </Grid.Item>
                                                        <Grid.Item span={2}
                                                            style={{
                                                                textAlign: "end", textAlign: "left", marginLeft: "-10px"
                                                            }}>
                                                            总金额
                                </Grid.Item>
                                                        <Grid.Item span={4} style={{ display: this.state.display }}>
                                                            <div style={{ textAlign: "right", marginRight: "10px" }}>
                                                                <span style={{ color: "#f00" }}>
                                                                    ￥10
                                                    </span>
                                                            </div>
                                                        </Grid.Item>
                                                    </Grid>
                                                </div>
                                                <div style={{
                                                    textAlign: "center", marginTop: "12px"
                                                    , borderTop: "solid 1px #e6e6e6", paddingTop: "7%"
                                                }}>
                                                    <Button block shape='rounded' onClick={() => {
                                                        Modal.clear();
                                                    }} style={{
                                                        width: "50%", margin: "auto"
                                                    }} color='primary'>
                                                        支付
                            </Button>
                                                </div>
                                            </>,
                                            closeOnMaskClick: true,
                                            actions: [],
                                        })

                                    }} color='primary' style={{ width: "90%", margin: "5px" }}>
                                        去购买
                                    </Button>
                                </Grid.Item>
                            </Grid>
                        </div>

                    </div>

                </Popup>


                <div className="landscape" style={{ }}></div>
                <div className="filter" style={{  }}></div>
                <canvas id="canvas"></canvas>

                <BottomMenu position="absolute" selectKey="home" />

            </div>
        )
    }
}

export default Index 
