import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Selector,TextArea,ImageUploader,DatePicker,NavBar,Toast ,FloatingBubble
} from 'antd-mobile-v5';
import dayjs from 'dayjs'
import { LeftOutline,EditSOutline,StarFill } from 'antd-mobile-icons'

import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'

import {
    GetMediaFile,CreateWxConfig,GetWXJSSDK
} from '../../service/service';

import { environment } from '../../service/baseUrl';

import '../DriftingDown/index.css'


class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
                {
                    key: 0,
                    url: "https://api.weibinyi.com/images/niannian/home_bg.jpg",
                    thumbnailUrl: "https://api.weibinyi.com/images/niannian/home_bg.jpg",
                }
            ],

        };
    }

    componentDidMount() {
        var that = this;

        // Keyframes for snowflake falling animation
        // document.styleSheets[0].insertRule(`
        //     @keyframes fall {
        //          to { top: -10px;opacity: 0; }
        //          from { top: 100%;opacity: 1; }
        //     }
        // `);


        setInterval(function () {
            that.createSnowflake();
        }, 2000);



    }


    createSnowflake = () => {
        const home = document.getElementById('xuehua');
        const snowflake = document.createElement('div');
        snowflake.classList.add('snowflake');
        var left = Math.random() * window.innerWidth;
        if (left < 0) {
            left = left * -1;
        }
        if (left > 0 && left < 50) {
            left = left + 50;
        }
        if (left > document.documentElement.clientWidth - 100) {
            left = left - 120;
        }
        snowflake.style.left = left + 'px';
        snowflake.style.fontSize = 100 + 'px';

        // const fallDuration = 8000;
        // snowflake.style.animation = `fall ${fallDuration}ms linear`;


        snowflake.innerHTML = '<img src="https://api.weibinyi.com/images/niannian/yj.png" width="100%" />';
        home.appendChild(snowflake);

        snowflake.addEventListener('click', function () {
            Toast.show({
                content: "点了一下",
                position: 'top',
            });
        });

        // Remove snowflake from DOM after it falls
        snowflake.addEventListener('animationend', () => {
            home.removeChild(snowflake);
        });

    }



    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div id="xuehua" style={{ height: "100%", width: "100%" }}>
                <NavBar back='' backArrow={<LeftOutline color='#fff' />} onBack={() => {
                    window.location.href = "/posts";
                }}>
                    <span style={{ color: "#fff" }}>寄思念</span>
                </NavBar>



                <FloatingBubble
                    axis='xy'
                    magnetic='x'
                    style={{
                        '--initial-position-bottom': '12%',
                        '--initial-position-right': '24px',
                        '--edge-distance': '24px',
                        '--size':'40px',
                        '--background':'#fff',
                    }}
                    onClick={()=>{
                        // window.location.href = "/postsAdd";
                        
                    }}
                >
                    <EditSOutline fontSize={25} color="#000" />
                </FloatingBubble>
                

            </div>
        )
    }
}

export default Index 