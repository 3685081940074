import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Selector,Ellipsis,List,Avatar,NavBar,Tabs,Grid,Popover,Toast
} from 'antd-mobile-v5';
import dayjs from 'dayjs'

import { RightOutline, MessageOutline, LikeOutline ,DownOutline} from 'antd-mobile-icons'
import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'

const actions = [
    { key: 'createTime', text: '按创建时间' },
    { key: 'zanCount', text: '按点赞数' },
    { key: 'commentCount', text: '按回复数' },
];

class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
               
            ],

        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);

        setTimeout(() => {
            var item = document.getElementsByClassName("am-list-item");
            if (item.length > 0) {
                for (let index = 0; index < item.length; index++) {
                    const element = item[index];
                    // element.style.marginBottom = "5px";

                    element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    element.style.paddingLeft = "0px";
                    if (element.innerText == "备注") {
                        element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    }

                }
            }
        }, 200);
    }

    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div style={{ height: "100%", width: "100%", overflow: "auto", backgroundColor: "#f4f4f4" }}>
                <NavBar back='返回' onBack={() => {
                    window.location.href = "/my";
                }} style={{ 'border-bottom': 'solid 1px #e4e4e4', backgroundColor: "#fff" }}>
                    我的发帖
                </NavBar>

                <div style={{ margin: "3% 2% 3% 2%", padding: "1% 2% 5% 2%", backgroundColor: "#fff" }}>
                    <div style={{ padding: "2% 5% 2% 3%", fontWeight: "600", fontSize: "17px" }}>
                        贴子统计
                        </div>
                    <div style={{ textAlign: "center" }}>
                        <div style={{ fontSize: "24px", fontWeight: "700" }}>66</div>
                        <div>发帖数</div>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "1%" }}>
                        <Grid columns={8} gap={8}>
                            <Grid.Item span={4}>
                                <div style={{ fontSize: "17px", fontWeight: "600" }}>888</div>
                                <div>获赞数</div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ fontSize: "17px", fontWeight: "600" }}>22</div>
                                <div>回复数</div>
                            </Grid.Item>
                        </Grid>
                    </div>
                </div>


                <div style={{ marginBottom: "17%" }}>

                    <NavBar right={
                        <div style={{ fontSize: 24 }}>
                            <Space>
                                
                                <Popover.Menu
                                    actions={actions.map(action => ({
                                        ...action,
                                        icon: null,
                                    }))}
                                    onAction={node => Toast.show(`选择了 ${node.text}`)}
                                    placement='bottom-start'
                                    trigger='click'
                                >
                                    <div>
                                        <span style={{ fontSize: "13px", marginRight: "4px" }}>按创建时间</span>
                                        <DownOutline fontSize={13} />
                                    </div>
                                </Popover.Menu>

                            </Space>
                        </div>
                    } back={<span>发帖列表</span>} backArrow={false}></NavBar>

                    <List header=''>
                        <List.Item onClick={() => {
                            window.location.href = "/myPostsInfo";
                        }} description='2024-04-27 17:19' extra={
                            <>
                                <MessageOutline fontSize={20} color='var(--adm-color-primary)' />
                                <span style={{ marginRight: "8px" }}>5</span>
                                <LikeOutline fontSize={20} color='var(--adm-color-primary)' />
                                <span>12</span>
                            </>
                        } clickable>
                            <div
                                style={{
                                    overflow: "hidden", width: "230px", fontSize: "13px"
                                    , whiteSpace: "nowrap", textOverflow: "ellipsis"
                                }}>
                                这是发帖内容这是发帖内容这是发帖内容这是发帖内容这是发帖内容
                            </div>
        </List.Item>
                        <List.Item onClick={() => {
                            window.location.href = "/myPostsInfo";
                        }} description='2024-04-27 17:19' extra={
                            <>
                                <MessageOutline fontSize={20} color='var(--adm-color-primary)' />
                                <span style={{ marginRight: "8px" }}>5</span>
                                <LikeOutline fontSize={20} color='var(--adm-color-primary)' />
                                <span>12</span>
                            </>
                        } clickable>
                            <div
                                style={{
                                    overflow: "hidden", width: "230px", fontSize: "13px"
                                    , whiteSpace: "nowrap", textOverflow: "ellipsis"
                                }}>
                                这是发帖内容这是发帖内容这是发帖内容这是发帖内容这是发帖内容
                            </div>
        </List.Item>

                    </List>

                </div>

                <BottomMenu  position="absolute" selectKey="my" />

            </div>
        )
    }
}

export default Index 