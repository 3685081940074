import React from 'react'
import {
    Form,
  Input,
  TextArea,
  Toast,
  Button,ImageUploader,Ellipsis,List,Picker,Modal,Dialog,NavBar
} from 'antd-mobile-v5';
import dayjs from 'dayjs'
import { MessageOutline, SetOutline,RightOutline } from 'antd-mobile-icons'
import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'


class Index extends React.Component {

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
               
            ],

        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);


    }


    render() {
        // const [form] = Form.useForm();
        // const onFinish = () => {
        //     const values = form.getFieldsValue()
        //     Dialog.alert({
        //         content: <pre>{JSON.stringify(values, null, 2)}</pre>,
        //     })
        // }

        const onFinish = (values) => {

            // Dialog.alert({
            //     content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>

                <NavBar back='返回' onBack={() => {
                    window.location.href = "/my";
                }}>
                    设置
                </NavBar>


                <div>
                    <List mode='card' header=''>
                        <List.Item extra={<RightOutline />}>
                            账号设置
      </List.Item>
                        <List.Item extra={<RightOutline />}>
                            编辑资料
      </List.Item>
                        <List.Item extra={<RightOutline />}>跟帖设置</List.Item>
                        <List.Item extra={<RightOutline />}>星球关系</List.Item>
                    </List>
                </div>

                <BottomMenu position="absolute" selectKey="my" />

            </div>
        )
    }
}

export default Index 