import React from "react"
import { Toast } from 'antd-mobile';
// import { connect } from "react-redux"
import { environment } from '../../service/baseUrl';
import { TabBar,Modal,Avatar } from 'antd-mobile-v5'
// import { DemoBlock } from 'demos';
import {
    AppOutline,
    MessageOutline,
    MessageFill,
    MovieOutline,
    UserOutline,StarOutline,SoundOutline,
  } from 'antd-mobile-icons'

import {
    GetWechatOpenId
} from '../../service/service';

// import { environment } from '../../assets/';


const mapState = (state) => {
    return {
        state
    }
}

const mapDispatch = () => {
    return {}
}





// @connect(mapState, mapDispatch)
class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'redTab',
            hidden: false,
            fullScreen: true,
            path: "index",
            invoiceState: 0,
            visible: false,
            productCount: 1,
            openid: "",
            userInfo: {},
            data: [
                // {
                //     icon: 'https://gw.alipayobjects.com/zos/rmsportal/nywPmnTAvTmLusPxHPSu.png',
                //     text: `花圈花篮`,
                //     path: "home"
                // },
                // {
                //     icon: 'https://gw.alipayobjects.com/zos/rmsportal/nywPmnTAvTmLusPxHPSu.png',
                //     text: `我的购买`,
                //     path: "payList"
                // },
            ],
            payTitle: "查询逝者",

            mapId: null,
            isShow: false,
            
            productList: [],
            imgList: [],
            imgCount: 0,


            position: props.position,
        };
    }

    componentDidMount() {
        var i = document.getElementsByTagName("meta");
        i[1]["content"] = "width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no";

        document.getElementsByTagName("body")[0].style.height = document.documentElement.clientHeight + "px";

        
        var elebottom = document.getElementById("bottom");
        elebottom.style.height = document.documentElement.clientHeight * 0.1 + "px";
        var elecbottom = document.getElementById("cbottom");
        elecbottom.style.height = document.documentElement.clientHeight * 0.1 + "px";


        var follow = localStorage.getItem("follow");
        if (follow) {

        } else {
            var state = true;
            var closeFollow = localStorage.getItem("closeFollow");
            if (closeFollow != undefined) {
                closeFollow = new Date(closeFollow);
                closeFollow.setMinutes(closeFollow.getMinutes() + 5);
                if (new Date() < closeFollow) {
                    state = false;
                }
            }

            if (state) {
                Modal.show({
                    content:
                        <>
                            <div style={{ marginTop: "3%" }}>
                                <Avatar src=''
                                    style={{ '--size': '55px', '--border-radius': '35px', 'margin': 'auto' }} />
                                <div style={{ textAlign: "center", marginTop: "4%" }}>
                                    念念星辰
                                    </div>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "9%", textDecoration: "underline" }}>
                                <a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIzOTA2OTIyMA==#wechat_redirect"
                                    style={{ color: "#0538ff" }}>
                                    关注公众号</a>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "4%", textDecoration: "underline", color: "#0538ff" }}>
                                <span onClick={() => {
                                    localStorage.setItem("follow", true);
                                    Modal.clear();
                                }}>已关注</span>
                            </div>
                        </>,
                    closeOnMaskClick: true,
                    actions: [],
                    afterClose: () => {
                        localStorage.setItem("closeFollow", new Date());
                    },
                })
            }

        }


        return ;

        var openid = localStorage.getItem("openid");

        var url = window.location.search; //获取url中"?"符后的字串
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
            var str = url.substr(1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
            }
        }

        if (openid == null || openid == "" || openid == undefined) {

            if (theRequest.code == undefined) {
                window.location.href = environment.wxredirecturi;

                // Toast.info("浏览器异常，请重新进入", 1);
                return;
            }
            GetWechatOpenId(theRequest.code).then(res => {
                if (res.success) {
                    if (res.result.item1) {
                        this.setState({
                            openid: res.result.item2
                        })
                        localStorage.setItem("openid", res.result.item2)

                        if (theRequest.state != null && theRequest.state != undefined) {
                            this.setState({
                                path: theRequest.path
                            })

                            if (theRequest.state == "index") {
                                this.setState({
                                    path: theRequest.state
                                })
                            } else {
                                this.InitDataByPath(theRequest.state);
                            }

                        }

                    } else {
                        Toast.info("内部错误！", 1);
                        this.setState({
                            path: ""
                        })
                    }
                } else {
                    Toast.info("内部错误！", 1);
                    this.setState({
                        path: ""
                    })
                }
            });
        } else {
            this.setState({
                openid: openid
            })

            if (theRequest.state != null && theRequest.state != undefined) {
                this.InitDataByPath(theRequest.state);
            }
        }


    }



    render() {
        const tabs = [
            {
                key: 'posts',
                title: '银河',
                icon: <MovieOutline />,
            },
            {
                key: 'home',
                title: '星球',
                icon: <StarOutline />,
                badge: '5',
            },
            {
                key: 'plan',
                title: '规划',
                icon: <SoundOutline />,
                badge: '99+',
            },
            {
                key: 'my',
                title: '我的',
                icon: <UserOutline />,
            },
        ]

        return (
            <div id="bottom" style={{
                backgroundColor: "#fff", bottom: "0px",
                position: this.state.position, width: "100%", borderTop: "solid 1px #dedede"
            }}>

                <div id="cbottom" style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>

                    <TabBar style={{
                        width: "100%"
                    }} safeArea defaultActiveKey={this.props.selectKey} onChange={(key) => {
                        switch (key) {
                            case "posts":
                                window.location.href = "/posts";
                                break;
                            case "home":
                                window.location.href = "/index";
                                break;
                            case "plan":
                                // window.location.href = "/msg";
                                break;
                            case "my":
                                window.location.href = "/my";
                                break;
                            default:
                                break;
                        }
                    }}>
                        {tabs.map(item => (
                            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                        ))}
                    </TabBar>

                </div>

            </div>
        );
    }
}

export default Tab 
