import { environment } from './baseUrl';


export function GetWechatOpenId(code) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetWechatOpenId?code=' + code, {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}
export function GetUserDtoByCode(code) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetUserDtoByCode?code=' + code, {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetUserInfoByOpenId() {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetUserInfoByOpenId', {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function CreateWxConfig() {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/CreateWxConfig', {
        method: 'post', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetWXJSSDK(url) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetWXJSSDK?url=' + url, {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetMediaFile(mediaId) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetMediaFile?mediaId=' + mediaId, {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}





export function UpdateWechatDeathInfo(params) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/UpdateWechatDeathInfo', {
        method: 'put', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function CreateOrderForm(params) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/CreateOrderForm', {
        method: 'post', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function CreateWecharOrderInfo(params) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/CreateWecharOrderInfo', {
        method: 'post', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetJsApiPay(params) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/CreateJsApiPay', {
        method: 'post', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}


export function GetWecharOrderInfo(id) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetWecharOrderInfo?id=' + id, {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetWecharOrderList(id) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetWecharOrderList', {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetImgsByTableIdAndTableName(tableId, tableName) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetImgsByTableIdAndTableName?tableId='
        + tableId + "&tableName=" + tableName, {
            method: 'get', //改成post
            mode: 'cors', //跨域
            headers: {
                //请求头{
                Accept: 'application/json',
                // Authorization: 'Bearer ' + localStorage.getItem("auth"),
                OpenId: localStorage.getItem("openid"),
                'Content-Type': 'application/json;charset=utf-8',
            },
            // body: JSON.stringify(params), //向服务器发送的数据
        }).then(response => response.json());
}

export function SaveOrderInfoImgs(params) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/SaveOrderInfoImgs', {
        method: 'post', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetWeChatMenu() {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetWeChatMenu', {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function UpdateOrderForm(params) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/UpdateOrderForm', {
        method: 'put', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetDeathListById() {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetDeathListById', {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetDeathList() {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetDeathList', {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetOrdereInfoById(id) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetOrdereInfoById?id=' + id, {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function SaveFile(formData) {
    return fetch(environment.apiEndpoint + '/api/FileUpload/SaveFile', {
        method: 'post', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            // Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            Authorization: "",
            OpenId: localStorage.getItem("openid"),
            // 'Content-Type': 'application/json;charset=utf-8',
        },
        body: formData, //向服务器发送的数据
    }).then(response => response.json());
}

export function SaveFiles(formData) {
    return fetch(environment.apiEndpoint + '/api/FileUpload/SaveFiles', {
        method: 'post', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            // Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            Authorization: "",
            OpenId: localStorage.getItem("openid"),
            // 'Content-Type': 'application/json;charset=utf-8',
        },
        body: formData, //向服务器发送的数据
    }).then(response => response.json());
}

export function GetWXUserInfoByOpenId() {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetWXUserInfoByOpenId', {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            'Content-Type': 'application/json;charset=utf-8',
            OpenId: localStorage.getItem("openid"),
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function UpdateCoupletIsDown(id) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/UpdateCoupletIsDown?id=' + id, {
        method: 'put', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetPayOrder(id) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetPayOrder?id=' + id, {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            'Content-Type': 'application/json;charset=utf-8',
            OpenId: localStorage.getItem("openid"),
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetProductListByMapId(id) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetProductListByMapId?id=' + id, {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            'Content-Type': 'application/json;charset=utf-8',
            OpenId: localStorage.getItem("openid"),
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function GetProductTempList() {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/GetProductTempList', {
        method: 'get', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            'Content-Type': 'application/json;charset=utf-8',
            OpenId: localStorage.getItem("openid"),
        },
        // body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function PutProductByDeathMapId(params) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/PutProductByDeathMapId', {
        method: 'put', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

export function CreateProductDeathMaps(params) {
    return fetch(environment.apiEndpoint + '/api/services/app/WeChatV2/CreateProductDeathMaps', {
        method: 'post', //改成post
        mode: 'cors', //跨域
        headers: {
            //请求头{
            Accept: 'application/json',
            // Authorization: 'Bearer ' + localStorage.getItem("auth"),
            OpenId: localStorage.getItem("openid"),
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params), //向服务器发送的数据
    }).then(response => response.json());
}

