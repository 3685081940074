import React, { useEffect } from "react";
import { useRoutes } from 'react-router-dom';

import Home from '@/pages/Home';
import FormView from '@/pages/FormView';
import FormEdit from '@/pages/FormEdit';
import UserAuth from '@/pages/UserAuth';
import Msg from '@/pages/Msg';
import MsgAdd from '@/pages/MsgAdd';
import AlbumClass from '@/pages/AlbumClass';
import AlbumUpload from '@/pages/AlbumUpload';
import Posts from '@/pages/Posts';
import PostsAdd from '@/pages/PostsAdd';
import My from '@/pages/My';
import Seting from '@/pages/Seting';
import Medias from '@/pages/Medias';
import ShoppingCart from '@/pages/ShoppingCart';
import MyPosts from '@/pages/MyPosts';
import MyPostsInfo from '@/pages/MyPostsInfo';
import DriftingDown from '@/pages/DriftingDown';
import Index from '@/pages/Index';

import { environment } from './service/baseUrl';
import {
  GetUserDtoByCode
} from './service/service';
import {
  Toast
} from 'antd-mobile';


var route = [
  { path: '/home', element: <Home /> },
  { path: '/formView', element: <FormView /> },
  { path: '/formEdit', element: <FormEdit /> },
  { path: '/userAuth', element: <UserAuth /> },
  { path: '/msg', element: <Msg /> },
  { path: '/msgAdd', element: <MsgAdd /> },
  { path: '/albumClass', element: <AlbumClass /> },
  { path: '/albumUpload', element: <AlbumUpload /> },
  { path: '/posts', element: <Posts /> },
  { path: '/postsAdd', element: <PostsAdd /> },
  { path: '/my', element: <My /> },
  { path: '/seting', element: <Seting /> },
  { path: '/medias', element: <Medias /> },
  { path: '/shoppingCart', element: <ShoppingCart /> },
  { path: '/myPosts', element: <MyPosts /> },
  { path: '/myPostsInfo', element: <MyPostsInfo /> },
  { path: '/driftingDown', element: <DriftingDown /> },
  { path: '/index', element: <Index /> },
  { path: '*', element: <Index /> },
];

function App() {

  useEffect(() => {
    return ;
    
    var openid = localStorage.getItem("openid");

    var url = window.location.search; //获取url中"?"符后的字串
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
      }
    }

    if (openid == null || openid == "" || openid == undefined) {

      if (theRequest.code == undefined) {
        window.location.href = environment.wxredirecturi;
        return;
      }
      GetUserDtoByCode(theRequest.code).then(res => {
        if (res.success) {
          if (res.result.item1) {
            localStorage.setItem("user", JSON.stringify(res.result.item2))
            localStorage.setItem("openid", res.result.item2.openId)

            window.location.reload();
          } else {
            Toast.info("内部错误！", 1);
          }
        } else {
          Toast.info("内部错误！", 1);
        }
      });
    } else {

      if (theRequest.state != null && theRequest.state != undefined) {

      }
    }

  });

  var ele = useRoutes(route);

  return (

    <div style={{height:"100%",height:"100%"}}>
      {ele}
    </div>

  );
}

export default App;
