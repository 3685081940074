import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Selector,TextArea,ImageUploader,DatePicker,NavBar
} from 'antd-mobile-v5';
import dayjs from 'dayjs'

import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'


class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
                {
                    key: 0,
                    url: "https://api.weibinyi.com/images/niannian/home_bg.jpg",
                    thumbnailUrl: "https://api.weibinyi.com/images/niannian/home_bg.jpg",
                }
            ],

        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);

        setTimeout(() => {
            var item = document.getElementsByClassName("am-list-item");
            if (item.length > 0) {
                for (let index = 0; index < item.length; index++) {
                    const element = item[index];
                    // element.style.marginBottom = "5px";

                    element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    element.style.paddingLeft = "0px";
                    if (element.innerText == "备注") {
                        element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    }

                }
            }
        }, 200);
    }

    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div>
                <NavBar back='返回' onBack={() => {
                    window.location.href = "/formView";
                }}>
                    编辑资料
                </NavBar>

                <Form
                    name='form'
                    onFinish={onFinish}
                    layout='horizontal'
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >

                    <Form.Item
                        name='name1'
                        label='头像'
                    >
                        <ImageUploader
                            columns={3}
                            maxCount={1}
                            defaultValue={this.state.fileList}
                            onChange={(file)=>{
                                this.setState({
                                    fileList: file
                                })
                            }}
                            upload={(file) => {
                                return {
                                    url: URL.createObjectURL(file),
                                }
                            }}
                        />
                    </Form.Item>


                    <Form.Item
                        name='name'
                        label='姓名'
                        rules={[{ required: true, message: '姓名不能为空' }]}
                    >
                        <Input onChange={console.log} placeholder='请输入姓名' />
                    </Form.Item>


                    <Form.Item name='sex' label='性别' required>
                        <Radio.Group>
                            <Space direction='horizontal'>
                                <Radio value='1'>男</Radio>
                                <Radio value='2'>女</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>


                    <Form.Item
                        name='name2'
                        label='民族'
                    >
                        <Input onChange={console.log} placeholder='请输入民族' />
                    </Form.Item>

                    {/* <Form.Item
                        name='name3'
                        label='籍贯'
                    >
                        <Input onChange={console.log} placeholder='请输入姓名' />
                    </Form.Item> */}


                    <Form.Item name='address1' label='信仰' help='详情地址'>
                        <Selector
                            options={[
                            {
                                label: '基督教',
                                value: '1',
                            },
                            {
                                label: '基督教2',
                                value: '2',
                                disabled: true,
                            },
                            {
                                label: '暂无',
                                value: '3',
                            },
                            ]}
                            defaultValue={['1']}
                            // onChange={(arr, extend) => {
                            //     console.log(arr, extend.items)
                            // }}
                        />
                    </Form.Item>


                    <Form.Item
                        name='name4'
                        label='职业'
                    >
                        <Input onChange={console.log} placeholder='请输入职业' />
                    </Form.Item>


                    <Form.Item
                        name='birthday'
                        label='出生日期'
                        trigger='onConfirm'
                        onClick={(e, datePickerRef) => {
                            datePickerRef.current.open()
                        }}
                    >
                        <DatePicker>
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                            }
                        </DatePicker>
                    </Form.Item>

                    <Form.Item
                        name='birthday1'
                        label='逝世日期'
                        trigger='onConfirm'
                        onClick={(e, datePickerRef) => {
                            datePickerRef.current.open()
                        }}
                    >
                        <DatePicker>
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                            }
                        </DatePicker>
                    </Form.Item>

                    <Form.Item name='address' label='简介' help='请输入简介'>
                        <TextArea
                            placeholder='请输入简介'
                            maxLength={100}
                            rows={2}
                            showCount
                        />
                    </Form.Item>



                </Form>



                <BottomMenu  position="sticky" selectKey="home" />

            </div>
        )
    }
}

export default Index 