import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Swiper,Ellipsis,Image,Grid,NavBar,Tabs,Avatar,Tag,SearchBar,FloatingBubble,Footer,List,ImageViewer
} from 'antd-mobile-v5';
import dayjs from 'dayjs'
import { MessageOutline, LikeOutline,BellOutline,MessageFill,EditSOutline ,SmileOutline} from 'antd-mobile-icons'

import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'


const demoImages = [
    'https://images.unsplash.com/photo-1620476214170-1d8080f65cdb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80',
    'https://images.unsplash.com/photo-1601128533718-374ffcca299b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3128&q=80',
    'https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3113&q=80',
    'https://images.unsplash.com/photo-1624993590528-4ee743c9896e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=1000&q=80',
  ]

class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
               
            ],

            visible: false,
        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);

        setTimeout(() => {
            var item = document.getElementsByClassName("am-list-item");
            if (item.length > 0) {
                for (let index = 0; index < item.length; index++) {
                    const element = item[index];
                    // element.style.marginBottom = "5px";

                    element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    element.style.paddingLeft = "0px";
                    if (element.innerText == "备注") {
                        element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    }

                }
            }
        }, 200);
    }

    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']

        const items = colors.map((color, index) => (
            <Swiper.Item key={index}>
                <div style={{ background: color, height: "140px" }}>
                    <Image src={"https://images.unsplash.com/photo-1620476214170-1d8080f65cdb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80"}
                        width={"100%"} height={"140px"} fit='cover' />
                </div>
            </Swiper.Item>
        ))

        return (
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                <NavBar back='返回' onBack={() => {
                    window.location.href = "/myPosts";
                }} style={{ 'border-bottom': 'solid 1px #e4e4e4', backgroundColor: "#fff" }}>
                    帖子详情
            </NavBar>


                <ImageViewer.Multi
                    images={demoImages}
                    visible={this.state.visible}
                    defaultIndex={1}
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                />

                <div style={{ marginBottom: "22%" }}>
                    <div>
                        <Grid columns={4} gap={8}>
                            <Grid.Item span={1}>
                                <div style={{ margin: "12px 0px 6px 12px" }}>
                                    <Avatar src={""} style={{ '--size': '48px', '--border-radius': '35px', 'margin': 'auto' }} />
                                </div>
                            </Grid.Item>
                            <Grid.Item span={3}>
                                <div style={{ height: "100%", paddingTop: "5%" }}>
                                    <div style={{ fontSize: "16px", fontWeight: "600" }}>张三</div>
                                    <div>3小时前</div>
                                </div>
                            </Grid.Item>
                        </Grid>
                        <div style={{ padding: "0px 20px 8px 20px", fontSize: "13px" }}>
                            <Ellipsis
                                direction='end'
                                defaultExpanded={true}
                                content={"蚂蚁的企业级产品是一个庞大且复杂的体系。这类产品不仅量级巨大且功能复杂，而且变动和并发频繁，常常需要设计与开发能够快速的做出响应。同时这类产品中有存在很多类似的页面以及组件，可以通过抽象得到一些稳定且高复用性的内容。"}
                                expandText='展开'
                                collapseText='收起'
                            />
                        </div>
                        <div style={{ padding: "0px 20px 4px 20px" }}>
                            <Grid columns={3} gap={8}>
                                <Grid.Item span={1}>
                                    <Image onClick={() => {
                                        this.setState({
                                            visible: true
                                        })
                                    }}
                                        src={"https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60"}
                                        width={"90%"}
                                        height={90}
                                        fit='cover'
                                        style={{ borderRadius: 4 }}
                                    />
                                </Grid.Item>
                                <Grid.Item span={1}>
                                    <Image onClick={() => {
                                        this.setState({
                                            visible: true
                                        })
                                    }}
                                        src={"https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60"}
                                        width={"90%"}
                                        height={90}
                                        fit='cover'
                                        style={{ borderRadius: 4 }}
                                    />
                                </Grid.Item>
                                <Grid.Item span={1}>
                                    <Image onClick={() => {
                                        this.setState({
                                            visible: true
                                        })
                                    }}
                                        src={"https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60"}
                                        width={"90%"}
                                        height={90}
                                        fit='cover'
                                        style={{ borderRadius: 4 }}
                                    />
                                </Grid.Item>
                                <Grid.Item span={1}>
                                    <Image onClick={() => {
                                        this.setState({
                                            visible: true
                                        })
                                    }}
                                        src={"https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60"}
                                        width={"90%"}
                                        height={90}
                                        fit='cover'
                                        style={{ borderRadius: 4 }}
                                    />
                                </Grid.Item>
                            </Grid>
                        </div>
                        <Grid columns={3} gap={8}>
                            <Grid.Item span={1}>
                                <div style={{ fontSize: "15px", paddingLeft: "20px", marginTop: "5px" }}>
                                    <Tag
                                        color='success'
                                        fill='outline'
                                        style={{ '--background-color': '#c8f7c5', 'font-size': '13px' }}
                                    >
                                        母亲123
                                </Tag>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ fontSize: "15px", paddingRight: "15px", textAlign: "end" }}>
                                    <MessageOutline fontSize={20} color='var(--adm-color-primary)' style={{ marginRight: "8px" }} />
                                    <LikeOutline fontSize={20} color='var(--adm-color-primary)' />
                                    <span>12</span>
                                </div>
                            </Grid.Item>
                        </Grid>
                        <div style={{ marginTop: "7px" }}>
                            <List header=''>
                                <List.Item
                                    key={"1"}
                                    prefix={
                                        <Image
                                            src={""}
                                            style={{ borderRadius: 20 }}
                                            fit='cover'
                                            width={40}
                                            height={40}
                                        />
                                    }
                                    description={
                                        <>
                                            <div>
                                                回复一下
                                        </div>
                                            <div>
                                                <div style={{ marginTop: "3px" }}>
                                                    <span style={{ marginRight: "5px", color: "#000", fontWeight: "600" }}>张三</span>
                                                    回复
                                                <span style={{ marginLeft: "5px", color: "#000", fontWeight: "600" }}>李四</span>：
                                                <span>已经回复了</span>
                                                </div>
                                                <div style={{ marginTop: "3px" }}>
                                                    <span style={{ marginRight: "5px", color: "#000", fontWeight: "600" }}>李四</span>
                                                    回复
                                                <span style={{ marginLeft: "5px", color: "#000", fontWeight: "600" }}>张三</span>：
                                                <span>好的</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                >
                                    李四
                                </List.Item>
                                
                                <List.Item
                                    key={"1"}
                                    prefix={
                                        <Image
                                            src={""}
                                            style={{ borderRadius: 20 }}
                                            fit='cover'
                                            width={40}
                                            height={40}
                                        />
                                    }
                                    description={
                                        <>
                                            <div>
                                                凑个热闹
                                        </div>
                                        </>
                                    }
                                >
                                    王五
                                </List.Item>
                                <List.Item
                                    key={"1"}
                                    prefix={
                                        <Image
                                            src={""}
                                            style={{ borderRadius: 20 }}
                                            fit='cover'
                                            width={40}
                                            height={40}
                                        />
                                    }
                                    description={
                                        <>
                                            <div>
                                                凑个热闹2
                                        </div>
                                        </>
                                    }
                                >
                                    赵六
                                </List.Item>

                            </List>
                        </div>
                      
                    </div>
               
                </div>


                <BottomMenu  position="absolute" selectKey="my" />

            </div>
        )
    }
}

export default Index 