import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Selector,Ellipsis,Image,SideBar,NavBar,Tabs,FloatingBubble
} from 'antd-mobile-v5';
import dayjs from 'dayjs'
import { MessageOutline, LikeOutline,BellOutline,MessageFill,UploadOutline } from 'antd-mobile-icons'
import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'


class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
               
            ],

        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);

        setTimeout(() => {
            var item = document.getElementsByClassName("am-list-item");
            if (item.length > 0) {
                for (let index = 0; index < item.length; index++) {
                    const element = item[index];
                    // element.style.marginBottom = "5px";

                    element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    element.style.paddingLeft = "0px";
                    if (element.innerText == "备注") {
                        element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    }

                }
            }
        }, 200);
    }

    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                <NavBar back='返回' onBack={() => {
                    window.location.href = "/home";
                }}>
                    相册分类列表
                </NavBar>

                <Tabs defaultActiveKey='1' padding='0' style={{
                    borderBottom: "solid 0px", position: "sticky",
                    top: "0px", zIndex: "100", backgroundColor: "#fff"
                }}>
                    <Tabs.Tab title='回忆相册' key='1' />
                    <Tabs.Tab title='纪念相册' key='2' />
                    <Tabs.Tab title='待分类' key='3' />
                </Tabs>


                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "stretch" }}>
                    <div style={{ flex: "none" }}>
                        <SideBar>
                            <SideBar.Item
                                key='key1'
                                title={
                                    <div>全部</div>
                                }
                            />
                            <SideBar.Item
                                key='key2'
                                title={
                                    <div>灵堂</div>
                                }
                            />
                            <SideBar.Item
                                key='key3'
                                title={
                                    <div>追悼会</div>
                                }
                            />
                            <SideBar.Item
                                key='key4'
                                title={
                                    <div>做七</div>
                                }
                            />
                        </SideBar>
                    </div>


                    <div style={{ marginBottom: "20%", marginLeft: "5%", marginRight: "5%", flex: "auto" }}>

                    {/* <Button block shape='rounded' color='success' style={{ marginTop: "5%" }}>
                            照片上传
          </Button> */}

                        <div style={{ marginTop: "5%" }}>
                            <Image lazy src={"https://images.unsplash.com/photo-1620476214170-1d8080f65cdb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80"} />
                        </div>
                        <div style={{ marginTop: "5%" }}>
                            <Image lazy src={"https://images.unsplash.com/photo-1620476214170-1d8080f65cdb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80"} />
                        </div>
                        <div style={{ marginTop: "5%" }}>
                            <Image lazy src={"https://images.unsplash.com/photo-1620476214170-1d8080f65cdb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80"} />
                        </div>
                        <div style={{ marginTop: "5%" }}>
                            <Image lazy src={"https://images.unsplash.com/photo-1620476214170-1d8080f65cdb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80"} />
                        </div>

                    </div>

                </div>

                
                <FloatingBubble
                    axis='xy'
                    magnetic='x'
                    style={{
                        '--initial-position-bottom': '12%',
                        '--initial-position-left': '20px',
                        '--edge-distance': '24px',
                        '--size':'40px'
                    }}
                    onClick={()=>{
                        window.location.href = "/albumUpload";
                    }}
                >
                    <UploadOutline fontSize={20} />
                </FloatingBubble>

                <BottomMenu  position="absolute" selectKey="home" />

            </div>
        )
    }
}

export default Index 