import React from 'react'
import {
    Form,
  Input,
  TextArea,
  Toast,
  Button,ImageUploader,Ellipsis,List,Picker,Modal,Dialog
} from 'antd-mobile-v5';
import dayjs from 'dayjs'

import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'


class Index extends React.Component {

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
               
            ],

        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);


    }


    render() {
        // const [form] = Form.useForm();
        // const onFinish = () => {
        //     const values = form.getFieldsValue()
        //     Dialog.alert({
        //         content: <pre>{JSON.stringify(values, null, 2)}</pre>,
        //     })
        // }

        const onFinish = (values) => {

            // Dialog.alert({
            //     content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>

                <Modal
                    visible={true}
                    title="追思留言"
                    content={
                        <Form
                            name='form'
                            onFinish={onFinish}
                            layout='horizontal'
                            footer={
                                <Button block type='submit' color='primary' size='large'>
                                    提交
                        </Button>
                            }
                        >


                            <Form.Item
                                name='name'
                                label='称呼'
                                rules={[{ required: true, message: '称呼不能为空' }]}
                            >
                                <Input onChange={console.log} placeholder='请输入称呼' />
                            </Form.Item>


                            <Form.Item name='sex' label='' required style={{ display: "none" }}>
                                <Input onChange={console.log} placeholder='请输入姓名' />
                            </Form.Item>

                            <List header=''>
                                <List.Item extra='请选择' onClick={async () => {
                                    const value = await Picker.prompt({
                                        columns: [
                                            [
                                                { label: '亲属', value: 'Mon' },
                                                { label: '朋友', value: 'Tues' },
                                                { label: '同事', value: 'Wed' },
                                                { label: '其他', value: 'Thur' },
                                                { label: '手动输入', value: 'Fri' },
                                            ]
                                        ]
                                    })
                                    Toast.show(`你选择了 ${value}`)
                                }}>
                                    关系
                                </List.Item>
                            </List>


                            <Form.Item
                                name='name1'
                                label='纪念照片'
                            >
                                <ImageUploader
                                    columns={2}
                                    maxCount={1}
                                    defaultValue={this.state.fileList}
                                    onChange={(file) => {
                                        this.setState({
                                            fileList: file
                                        })
                                    }}
                                    upload={(file) => {
                                        return {
                                            url: URL.createObjectURL(file),
                                        }
                                    }}
                                />
                            </Form.Item>


                            <Form.Item name='address' label='' help=''>
                                <TextArea
                                    placeholder='请您写一则回忆故事或致意之词'
                                    maxLength={500}
                                    rows={2}
                                    showCount
                                />
                            </Form.Item>

                        </Form>
                    }
                    closeOnAction
                    showCloseButton
                    onClose={() => {
                        window.location.href = "msg";
                    }}
                    onAction={()=>{
                        onFinish();
                    }}
                    actions={[
                        // {
                        //     key: 'submit',
                        //     text: '提交',
                        //     primary: true,
                        // },
                    ]}
                />

                {/* <Card title='追思留言' onClick={()=>{

                }}>
                    卡片内容
                </Card>

                <BottomMenu  position="absolute" /> */}

            </div>
        )
    }
}

export default Index 