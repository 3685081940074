import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Selector,TextArea,Image,DatePicker,Divider,Grid,NavBar,Ellipsis
} from 'antd-mobile-v5';
import { FillinOutline,StarOutline,StarFill,AppOutline } from 'antd-mobile-icons'
import dayjs from 'dayjs'

import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'


class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
                {
                    key: 0,
                    url: "https://api.weibinyi.com/images/niannian/home_bg.jpg",
                    thumbnailUrl: "https://api.weibinyi.com/images/niannian/home_bg.jpg",
                }
            ],

        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);

        setTimeout(() => {
            var item = document.getElementsByClassName("am-list-item");
            if (item.length > 0) {
                for (let index = 0; index < item.length; index++) {
                    const element = item[index];
                    // element.style.marginBottom = "5px";

                    element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    element.style.paddingLeft = "0px";
                    if (element.innerText == "备注") {
                        element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    }

                }
            }
        }, 200);
    }

    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div>
                <NavBar back='返回' onBack={() => {
                    window.location.href = "/home";
                }} right={<FillinOutline fontSize="25px" color='var(--adm-color-primary)' onClick={() => {
                    window.location.href = "/formEdit";
                }} />}>
                    生平
                </NavBar>

                <Form
                    name='form'
                    layout='horizontal'
                >


                    <div style={{ position: "absolute", right: "10px", marginTop: "-8px" }}>
                        <span style={{ fontSize: "13px", textDecoration: "underline" }}>存储仓库</span>
                        <AppOutline fontSize={13} color='var(--adm-color-danger)' />
                    </div>

                    <Grid columns={4} gap={8} style={{ marginTop: "15px" }}>
                        <Grid.Item>
                                <Image
                                    src={"https://api.weibinyi.com/images/niannian/home_bg.jpg"}
                                    width={70}
                                    height={70}
                                    fit='cover'
                                    style={{ borderRadius: "45px",marginLeft:"15px" }}
                                />
                        </Grid.Item>
                        <Grid.Item span={3}>
                            <div style={{ marginLeft: "15px", lineHeight: "20px" }}>
                                <div style={{ marginTop: "5px" }}>
                                    某某某
                                </div>
                                <div>已化作星星1111天</div>
                                <div>ID：123456</div>
                            </div>
                        </Grid.Item>
                    </Grid>

                   

                    <Divider>基本信息</Divider>

                    <Form.Item
                        name='name'
                        label='性别'
                        rules={[{ required: true, message: '姓名不能为空' }]}
                    >
                        女
                    </Form.Item>


                    <Form.Item
                        name='name2'
                        label='民族'
                    >
                        汉
                    </Form.Item>



                    <Form.Item name='address1' label='信仰' help='详情地址'>
                        基督教
                    </Form.Item>


                    <Form.Item
                        name='name4'
                        label='职业'
                    >
                        农民
                    </Form.Item>


                    <Form.Item
                        name='birthday'
                        label='出生日期'
                    >
                        2024年4月14日13点04分
                    </Form.Item>

                    <Form.Item
                        name='birthday1'
                        label='逝世日期'
                    >
                        2024年4月14日13点04分
                    </Form.Item>

                    <Form.Item name='address' label='简介' help='详情地址'>
                        <Ellipsis
                            content={"蚂蚁的企业级产品是一个庞大且复杂的体系。这类产品不仅量级巨大且功能复杂，而且变动和并发频繁，常常需要设计与开发能够快速的做出响应。同时这类产品中有存在很多类似的页面以及组件，可以通过抽象得到一些稳定且高复用性的内容。"}
                            defaultExpanded={true}
                            expandText='展开'
                            collapseText='收起'
                        />
                    </Form.Item>



                    <Divider>星球相关</Divider>

                    <Form.Item name='sex' label='是否安葬' required>
                        <Radio.Group>
                            <Space direction='horizontal'>
                                <Radio value='1'>是</Radio>
                                <Radio value='2'>否</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Divider>亲友团</Divider>

                    <Space wrap style={{ marginLeft: "8px", marginRight: "8px" }}>
                        <Image
                            src={"https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60"}
                            width={60}
                            height={60}
                            fit='cover'
                            style={{ borderRadius: 32 }}
                        />
                        <Image
                            src={"https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60"}
                            width={60}
                            height={60}
                            fit='cover'
                            style={{ borderRadius: 32 }}
                        />
                        <Image
                            src={"https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60"}
                            width={60}
                            height={60}
                            fit='cover'
                            style={{ borderRadius: 32 }}
                        />
                    </Space>

                </Form>



                <BottomMenu  position="sticky" selectKey="home" />

            </div>
        )
    }
}

export default Index 