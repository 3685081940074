import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Selector,Ellipsis,List,Avatar,NavBar,Tabs,SearchBar,Card,Grid
} from 'antd-mobile-v5';
import dayjs from 'dayjs'

import { RightOutline } from 'antd-mobile-icons'
import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'


class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
               
            ],

            display: "none"
        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);

        setTimeout(() => {
            var item = document.getElementsByClassName("am-list-item");
            if (item.length > 0) {
                for (let index = 0; index < item.length; index++) {
                    const element = item[index];
                    // element.style.marginBottom = "5px";

                    element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    element.style.paddingLeft = "0px";
                    if (element.innerText == "备注") {
                        element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    }

                }
            }
        }, 200);
    }

    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                <NavBar back='返回' onBack={() => {
                    window.location.href = "/my";
                }} style={{ 'border-bottom': 'solid 1px #e4e4e4' }}>
                    我的订单
                </NavBar>

                <div style={{ margin: "3%" }}>
                    <SearchBar placeholder='请输入订单号' showCancelButton cancelText="搜索" />
                </div>

                <Tabs defaultActiveKey='1' padding='0' style={{
                    borderBottom: "solid 0px", position: "sticky",
                    top: "0px", zIndex: "100", backgroundColor: "#fff"
                }}>
                    <Tabs.Tab title='全部' key='1' />
                    <Tabs.Tab title='鲜花' key='2' />
                    <Tabs.Tab title='上香' key='3' />
                    <Tabs.Tab title='贡品' key='4' />
                    <Tabs.Tab title='其他' key='6' />
                </Tabs>


                <div style={{ margin: "5% 10% 17%" }}>
                    <Card
                        headerStyle={{
                            color: '#1677ff',
                            paddingBottom: "8px"
                        }}
                        title='W20240427001'
                        style={{ border: "solid 1px #eaeaea", marginTop: "15px" }}
                    >
                        
                        <div>
                            <Grid columns={8} gap={8}>
                                <Grid.Item span={2}
                                    style={{ textAlign: "end", fontWeight: "900" }}>
                                    购买时间：
                                </Grid.Item>
                                <Grid.Item span={6}>
                                    2024-4-27 15:17
                                </Grid.Item>
                                <Grid.Item span={2} 
                                style={{ textAlign: "end", fontWeight: "900" }}>
                                    产品名称：
                                </Grid.Item>
                                <Grid.Item span={6}>
                                    <div>
                                        <Grid columns={6} gap={8}>
                                            <Grid.Item span={2} style={{ textAlign: "end", fontWeight: "900",textAlign:"left" }}>
                                                鲜花1
                                </Grid.Item>
                                            <Grid.Item span={this.state.display == "none" ? 1 : 3}>
                                                *1
                                </Grid.Item>
                                            <Grid.Item span={2}
                                                style={{
                                                    textAlign: "end", fontWeight: "900", textAlign: "left"
                                                    , display: this.state.display
                                                }}>
                                                鲜花2
                                </Grid.Item>
                                            <Grid.Item span={1} style={{ display: this.state.display }}>
                                                *11
                                </Grid.Item>
                                            <Grid.Item span={3}>
                                                <span style={{ textDecoration: "underline", color: "#004cff" }}
                                                    onClick={() => {
                                                        var dis = this.state.display;
                                                        if (dis == "none") {
                                                            dis = "block";
                                                        } else {
                                                            dis = "none";
                                                        }
                                                        this.setState({
                                                            display: dis
                                                        })
                                                    }}>
                                                    {
                                                        this.state.display == "none" ? "展开" : "收起"
                                                    }
                                                </span>
                                </Grid.Item>
                                        </Grid>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={2} 
                                style={{ textAlign: "end", fontWeight: "900" }}>
                                    支付金额：
                                </Grid.Item>
                                <Grid.Item span={6}>
                                    ￥12
                                </Grid.Item>
                            </Grid>

                        </div>

                    </Card>

                    
                    <Card
                        headerStyle={{
                            color: '#1677ff',
                            paddingBottom: "8px"
                        }}
                        title='W20240427002'
                        style={{ border: "solid 1px #eaeaea", marginTop: "15px" }}
                    >
                        
                        <div>
                            <Grid columns={8} gap={8}>
                                <Grid.Item span={2}
                                    style={{ textAlign: "end", fontWeight: "900" }}>
                                    购买时间：
                                </Grid.Item>
                                <Grid.Item span={6}>
                                    2024-4-27 15:17
                                </Grid.Item>
                                <Grid.Item span={2} 
                                style={{ textAlign: "end", fontWeight: "900" }}>
                                    产品名称：
                                </Grid.Item>
                                <Grid.Item span={6}>
                                    <div>
                                        <Grid columns={6} gap={8}>
                                            <Grid.Item span={2} style={{ textAlign: "end", fontWeight: "900",textAlign:"left" }}>
                                                鲜花1
                                </Grid.Item>
                                            <Grid.Item span={this.state.display == "none" ? 1 : 3}>
                                                *1
                                </Grid.Item>
                                            <Grid.Item span={2}
                                                style={{
                                                    textAlign: "end", fontWeight: "900", textAlign: "left"
                                                    , display: this.state.display
                                                }}>
                                                鲜花2
                                </Grid.Item>
                                            <Grid.Item span={1} style={{ display: this.state.display }}>
                                                *11
                                </Grid.Item>
                                            <Grid.Item span={3}>
                                                <span style={{ textDecoration: "underline", color: "#004cff" }}
                                                    onClick={() => {
                                                        var dis = this.state.display;
                                                        if (dis == "none") {
                                                            dis = "block";
                                                        } else {
                                                            dis = "none";
                                                        }
                                                        this.setState({
                                                            display: dis
                                                        })
                                                    }}>
                                                    {
                                                        this.state.display == "none" ? "展开" : "收起"
                                                    }
                                                </span>
                                </Grid.Item>
                                        </Grid>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={2} 
                                style={{ textAlign: "end", fontWeight: "900" }}>
                                    支付金额：
                                </Grid.Item>
                                <Grid.Item span={6}>
                                    ￥12
                                </Grid.Item>
                            </Grid>

                        </div>

                    </Card>

                </div>

                <BottomMenu  position="absolute" selectKey="my" />

            </div>
        )
    }
}

export default Index 