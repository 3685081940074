import React from 'react'

import {
    Form,
    Tabs,
  Badge,
  TabBar,
  Button,ImageViewer,SideBar,List,Image,Grid,Popup,Stepper,Modal, Toast,Avatar,FloatingBubble
} from 'antd-mobile-v5';
import { HandPayCircleOutline,StarOutline,DownCircleOutline } from 'antd-mobile-icons'

import BottomMenu from '../../comronent/BottomMenu/index';
import 'antd/dist/antd.css';
import '../../assets/font/font.css'
import '../Index/index.css'

import {
    GetDeathList,GetWXJSSDK
} from '../../service/service';

import { environment } from '../../service/baseUrl';

class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            dataMsg: "数据加载中...",

            visibleQF: false,
            visibleQFOver: false,
            visibleXH: false,
            visibleImg: false,

            offset: {
                x: -228, 
                y: -532
            },
        };
    }

    componentDidMount() {
        // const script = document.createElement('script');
        // script.src = "https://niannian.weimuyuan.com/jquery.min.js";
        // script.async = true;
        // document.body.appendChild(script);

        // const script1 = document.createElement('script');
        // script1.src = "https://niannian.weimuyuan.com/prefixfree.min.js";
        // script1.async = true;
        // document.body.appendChild(script1);

        // const script2 = document.createElement('script');
        // script2.src = "https://niannian.weimuyuan.com/stopExecutionOnTimeout.js";
        // script2.async = true;
        // document.body.appendChild(script2);


        //星星位置
        var offset = localStorage.getItem("offset");
        if (offset != undefined) {
            this.setState({
                offset: JSON.parse(offset)
            })
        }

        // setTimeout(() => {
        //     var index = document.getElementById("index");
        //     var root = document.getElementById("root");
        //     var xingqiu = document.getElementById("xingqiu");
        //     xingqiu.style.bottom = (parseInt(window.$(index).height()) + (parseInt(window.$(root).height()) * 0.04)) + "px";
        // }, 500);

        // setTimeout(() => {

        //     var stars = 800;
        //     var $stars = window.$('.stars');
        //     var r = 800;
        //     for (var i = 0; i < stars; i++) {
        //         if (window.CP.shouldStopExecution(1)) {
        //             break;
        //         }
        //         var $star = window.$('<div/>').addClass('star');
        //         $stars.append($star);
        //     }
        //     window.CP.exitedLoop(1);
        //     window.$('.star').each(function () {
        //         var cur = window.$(this);
        //         var s = 0.2 + Math.random() * 1;
        //         var curR = r + Math.random() * 300;
        //         cur.css({
        //             transformOrigin: '0 0 ' + curR + 'px',
        //             transform: ' translate3d(0,0,-' + curR + 'px) rotateY(' + Math.random() * 360 + 'deg) rotateX(' + Math.random() * -50 + 'deg) scale(' + s + ',' + s + ')'
        //         });
        //     });


        //     var bodyIndex = document.getElementById("bodyIndex");
        //     bodyIndex.style.display = "block";

        // }, 2000);





        
        // const script = document.createElement('script');
        // script.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
        // script.async = true;
        // document.body.appendChild(script);



        
        
        const script = document.createElement('script');
        script.src = "https://niannian.weimuyuan.com/index_DH.js";
        script.async = true;
        document.body.appendChild(script);

    }

    


    render() {

        

        return (
            <div id="home" className="home" style={{ width: "100%", height: "100%" }}>

                <div id="stars" className="stars"></div>


                <FloatingBubble
                    axis='xy'
                    style={{
                        '--initial-position-bottom': '12%',
                        '--initial-position-right': '24px',
                        '--edge-distance': '24px',
                        '--size': '40px',
                        '--border-radius': '0',
                        '--background': '#ffffff00',
                    }}
                    onClick={() => {
                        window.location.href = "/home";
                    }}
                    onOffsetChange={offset => {
                        this.setState({
                            offset: offset
                        })
                        localStorage.setItem("offset", JSON.stringify(offset));
                    }}
                    offset={this.state.offset}
                >
                    <>
                        <div style={{ width: "100%", position: "absolute", textAlign: "center", marginTop: "-15px" }}>
                            <Image src={"https://api.weibinyi.com/images/niannian/home_star1.png"}
                                width={20} height={20} fit='cover' style={{ margin: "auto" }} />
                        </div>
                        <div style={{
                            color: "#fff",
                            fontSize: "10px",
                            marginTop: "23px",
                            width: "100%",
                            position: "absolute",
                            textAlign: "center"
                        }}>
                            张三三
                        </div>
                    </>
                </FloatingBubble>


                {/* <div style={{ position: "absolute", margin: "100px" }}>
                    <div style={{ margin: "auto", width: "20px" }} onClick={() => {
                        window.location.href = "/home";
                    }}>
                        <Image src={"https://api.weibinyi.com/images/niannian/home_star1.png"}
                            width={20} height={20} fit='cover' />
                    </div>
                    <div style={{ color: "#fff", fontSize: "10px", marginTop: "3px" }}>张三三</div>
                </div> */}



                <div id="xingqiu" style={{ position: "absolute", bottom: "12%", width: "100%", display: "none" }}>

                    <div style={{ fontSize: "19px", color: "#fff", marginBottom: "10px", marginLeft: "10px" }}>
                        我的星球
                    </div>

                    <div style={{ width: "100%" }}>
                        <div style={{
                            width: "135px",
                            backgroundColor: "rgb(110 110 110 / 56%)",
                            border: "solid 1px #848484",
                            height: "180px",
                            borderRadius: "8%",
                            marginLeft: "5%",
                            float: "left"
                        }} onClick={() => {
                            window.location.href = "/home";
                        }}>
                            <div style={{ marginTop: "14%" }}>
                                <Avatar src=''
                                    style={{ '--size': '55px', '--border-radius': '35px', 'margin': 'auto' }} />
                            </div>
                            <div style={{ textAlign: "center", marginTop: "5%", color: "#fff", fontSize: "14px" }}>张三三</div>
                            <div style={{ textAlign: "center", marginTop: "3%", color: "#d7d7d7", fontSize: "12px" }}>
                                已化作星星100天
                            </div>
                            <div style={{ textAlign: "center", color: "#eaeaea", fontSize: "23px", marginTop: "6%" }}>
                                <DownCircleOutline />
                            </div>
                        </div>
                        
                        <div style={{
                            width: "135px",
                            backgroundColor: "rgb(110 110 110 / 56%)",
                            border: "solid 1px #848484",
                            height: "180px",
                            borderRadius: "8%",
                            marginLeft: "5%",
                            float: "left"
                        }} onClick={() => {
                            window.location.href = "/home";
                        }}>
                            <div style={{ marginTop: "28%" }}>
                                <Avatar src=''
                                    style={{ '--size': '55px', '--border-radius': '35px', 'margin': 'auto' }} />
                            </div>
                            <div style={{ textAlign: "center", marginTop: "13%", color: "#fff", fontSize: "14px" }}>
                                免费创建
                            </div>
                        </div>
                        

                    </div>
                </div>
                




                <div className="landscape" style={{ }}></div>
                <div className="filter" style={{  }}></div>
                <canvas id="canvas"></canvas>


                <BottomMenu position="absolute" selectKey="home" />
                
            </div>
        )
    }
}

export default Index 
