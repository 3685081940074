import React from 'react'
import {
    Form,
  Input,
  Radio,
  Space,
  Button,Selector,TextArea,ImageUploader,DatePicker,NavBar,Grid
} from 'antd-mobile-v5';
import dayjs from 'dayjs'

import BottomMenu from '../../comronent/BottomMenu/index';
import '../../assets/font/font.css'


class Index extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        var arry = [];
        for (let index = 0; index < props.productCount; index++) {
            arry.push(index);
        }

        this.state = {
            arry: arry,

            fileList:[
               
            ],

        };
    }

    componentDidMount() {
        console.log(this.props.userInfo);

        setTimeout(() => {
            var item = document.getElementsByClassName("am-list-item");
            if (item.length > 0) {
                for (let index = 0; index < item.length; index++) {
                    const element = item[index];
                    // element.style.marginBottom = "5px";

                    element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    element.style.paddingLeft = "0px";
                    if (element.innerText == "备注") {
                        element.style.backgroundColor = "rgba(236, 232, 232, 0)";
                    }

                }
            }
        }, 200);
    }

    submitForm = () => {
        var value = this.props.form.getFieldsValue();
        

        var sumPrice = this.props.userInfo.productCount * this.props.userInfo.productPrice;
        console.log(value);

        this.props.submitClick(sumPrice);
    }
    


    render() {

        const onFinish = (values) => {

            // Dialog.alert({
            //   content: <pre>{JSON.stringify(values, null, 2)}</pre>,
            // })
        }


        return (
            <div>
                <NavBar back='返回' onBack={() => {
                    window.location.href = "/my";
                }}>
                    认证唯一性
                </NavBar>

                <Form
                    name='form'
                    onFinish={onFinish}
                    layout='horizontal'
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >


                    <Form.Item
                        name='name'
                        label='管理员真实姓名'
                        rules={[{ required: true, message: '姓名不能为空' }]}
                    >
                        <Input onChange={console.log} placeholder='请输入姓名' />
                    </Form.Item>


                    <Form.Item
                        name='name1'
                        label='管理员身份证'
                    >
                        <ImageUploader
                            columns={2}
                            maxCount={1}
                            defaultValue={this.state.fileList}
                            onChange={(file)=>{
                                this.setState({
                                    fileList: file
                                })
                            }}
                            upload={(file) => {
                                return {
                                    url: URL.createObjectURL(file),
                                }
                            }}
                        />
                    </Form.Item>


                    <Form.Item
                        name='name2'
                        label='逝者身份证号'
                        rules={[{ required: true, message: '姓名不能为空' }]}
                    >
                        <Input onChange={console.log} placeholder='请输入姓名' />
                    </Form.Item>


                    <Form.Item
                        name='name3'
                        label='逝者火化证原件'
                    >
                        <ImageUploader
                            columns={2}
                            maxCount={1}
                            defaultValue={this.state.fileList}
                            onChange={(file)=>{
                                this.setState({
                                    fileList: file
                                })
                            }}
                            upload={(file) => {
                                return {
                                    url: URL.createObjectURL(file),
                                }
                            }}
                        />
                    </Form.Item>





                </Form>


                <div style={{ marginRight: "5%" }}>
                    <Grid columns={6} gap={8}>
                        <Grid.Item span={1} style={{ textAlign: "end", fontWeight: "900" }}>
                            告知
                        </Grid.Item>
                        <Grid.Item span={5}>
                            <div>1、请确保上传资料的真实有效；</div>
                            <div>2、认证完毕后，念念星辰就不再接受相同姓名、出生日期和过世日期的逝者提交的创建申请。有相同的星星也会将其屏蔽。</div>
                        </Grid.Item>
                    </Grid>

                </div>


                <BottomMenu  position="absolute" selectKey="my" />

            </div>
        )
    }
}

export default Index 